<template>
  <div id="page-content">
    <div class="jumbotron d-flex flex-column jumbo">
      <div class="container d-flex flex-column p-3 jumbo__container rounded">
        <h1 class="text-center text-md-left jumbo__container__heading">
          Cabinet d'orthoptie à Tournefeuille
        </h1>
        <p class="text-left jumbo__container__paragraph">
          Le cabinet d’orthoptie de Mme Barès vous accueille à Tournefeuille au
          sein d’un centre pluridisciplinaire. L’orthoptiste a pour actions de
          dépister, rééduquer, réadapter et explorer la fonction visuelle ; du
          bébé à la personne âgée.
        </p>
        <p class="text-left jumbo__container__paragraph">
          Les symptômes pouvant vous amener à consulter un orthoptiste sont
          multiples et variés (maux de tête, fatigue visuelle, strabisme,
          troubles des apprentissages…).
        </p>
        <p class="text-left jumbo__container__paragraph">
          L’orthoptiste travaille sur prescription médicale (généraliste,
          pédiatre, ophtalmologiste, ORL, neurologue…) pour réaliser des bilans
          orthoptiques et la rééducation pouvant en résulter. Sur indication
          médicale, des examens complémentaires peuvent être effectués (test de
          la vision des couleurs, champ visuel automatisé, rétinographie non
          mydriatique, renouvellement de la correction optique).
          <br />
        </p>
        <div
          class="buttons d-flex flex-column flex-lg-row justify-content-around"
        >
          <button
            class="btn btn-primary text-light btn-lg shadow-sm mx-auto"
            type="button"
            data-toggle="modal"
            data-target="#bootstrapModal"
          >
            Prendre RDV
          </button>
          <router-link
            class="
              btn btn-primary
              text-light
              btn-lg
              shadow-sm
              mx-auto
              my-3 my-lg-0
            "
            to="/contact"
          >
            Contactez-nous</router-link
          >
        </div>
      </div>
      <p class="mx-auto"></p>
    </div>
    <!-- Hero zone -->
    <div class="container">
      <!-- 3 cartes -->
      <div class="row d-flex justify-content-center">
        <div
          class="col offset-xl-0 d-flex col-12 col-md-6 col-lg-4 my-2 my-lg-0"
        >
          <div class="card">
            <div class="card-body px-0 pt-0">
              <img
                class="card-img-top"
                src="../assets/img/accueil-pro.jpg"
                alt="Les professionnelles"
              />
              <h4 class="card-title mt-1 px-2">
                Une professionnelle à votre écoute
              </h4>
              <p class="card-text text-justify px-2">
                Les orthoptistes sont en lien étroit avec les ophtalmologistes
                et sont donc à votre écoute afin d’optimiser la prise en charge
                de la filière visuelle. Les orthoptistes sont aussi à vos côtés
                pour accompagner les enfants en difficultés d'apprentissages.
              </p>
            </div>
            <div class="card-footer bg-light d-flex justify-content-center">
              <router-link
                to="/cabinet"
                class="btn btn-primary shadow-sm text-white"
                >Le cabinet
              </router-link>
            </div>
          </div>
        </div>
        <div
          class="col offset-xl-0 d-flex col-12 col-md-6 col-lg-4 my-2 my-lg-0"
        >
          <div class="card">
            <div class="card-body d-flex flex-column px-0 pt-0">
              <img
                class="card-img-top"
                src="../assets/img/accueil-consult.jpg"
                alt="Une consultation orthoptique"
              />
              <div class="d-flex flex-grow-1 flex-column">
                <h4 class="card-title mt-1 px-2">Différentes consultations</h4>
                <p class="card-text text-justify px-2 m-auto">
                  Le cabinet d’orthoptie de Tournefeuille réalise des bilans et
                  rééducations traditionnels, le renouvellement des lunettes, le
                  dépistage de la rétinopathie diabétique, l’exploration du
                  champ visuel automatisé, de la vision des couleurs et l’examen
                  neuro-visuel.
                </p>
              </div>
            </div>
            <div class="card-footer bg-light d-flex justify-content-center">
              <router-link
                to="/consultations"
                class="btn btn-primary shadow-sm text-white"
                >Les consultations</router-link
              >
            </div>
          </div>
        </div>
        <div
          class="col offset-xl-0 d-flex col-12 col-md-6 col-lg-4 my-2 my-lg-0"
        >
          <div class="card">
            <div class="card-body d-flex flex-column px-0 pt-0">
              <img
                class="card-img-top"
                src="../assets/img/accueil-acces.jpg"
                alt="Accessibilité et facilité d'accès"
              />
              <div class="d-flex flex-grow-1 flex-column">
                <h4 class="card-title mt-1 px-2">Un cabinet accessible</h4>
                <p class="card-text text-justify px-2 m-auto">
                  Situé à proximité des transports en commun, du centre-ville et
                  des axes de circulation. Le cabinet d'orthoptie de Mme Barès
                  vous accueille sur rendez-vous, dans le respect des normes
                  d’accessibilité à mobilité réduite, sur des horaires étendus.
                </p>
              </div>
            </div>
            <div class="card-footer bg-light d-flex justify-content-center">
              <router-link
                to="/infos"
                class="btn btn-primary shadow-sm text-white"
                >Infos pratiques
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- Carousel - 3 photos -->
      <div class="row my-4">
        <Carousel keyCarousel="accueil" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Carousel from "../components/Carousel";
// @ is an alias to /src

export default {
  name: "Home",
  components: {
    Carousel,
  },
  mounted() {
    this.updateActive(this.$route);
    document.title = "Camille Barès - Orthoptiste à Tournefeuille";
    window.scrollTo(0, 0);
  },
  methods: {
    ...mapActions(["updateActive"]),
  },
};
</script>

<style lang="scss" scoped>
</style>