<template>
<!-- 
    Bootstrap 4 modal, careful with the ID as it's referenced in Navbar and buttons that open it
 -->
    <div>
        <div class="modal fade" id="bootstrapModal" tabindex="-1" role="dialog" aria-labelledby="modalRdv" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalTitle">
                            <slot name="title">Information</slot>
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <slot name="content"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'BootstrapModal'
    }
</script>

<style lang="scss" scoped>

</style>