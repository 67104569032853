import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Nprogress from 'vue-nprogress'
import store from '../store/index';

Vue.use(VueRouter);
Vue.use(Nprogress);

// if menu is not collapsed during nav (mobile), menu is closed while routing
export const closeMenu = () => {
  if (document.querySelector('.navbar-collapse')) {
    window.$('.navbar-collapse').collapse('hide');
  }
}

// if the bootstrapModal is open during nav (mobile), it's closed while routing
export const closeModal = () => {
  const body = document.querySelector('body');
  if(body.classList.contains('modal-open')){
    window.$('#bootstrapModal').modal('hide');
  }
}

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/cabinet',
    name: 'Cabinet',
    component: () => import( /* webpackChunkName: "cabinet" */ '../views/Cabinet.vue')
  },
  {
    path: '/consultations',
    name: 'Consultations',
    component: () => import( /* webpackChunkName: "consultations" */ '../views/Consultations.vue')
  },
  {
    path: '/infos',
    name: 'Infos',
    component: () => import( /* webpackChunkName: "infos" */ '../views/Infos.vue')
  },
  {
    path: '/mentionslegales',
    name: 'MentionsLegales',
    component: () => import( /* webpackChunkName: "mentionslegales" */ '../views/MentionsLegales.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import( /* webpackChunkName: "contact" */ '../views/Contact.vue')
  },
  {
    path: '/covid',
    name: 'Covid',
    component: () => import( /* webpackChunkName: "covid" */ '../views/Covid.vue')
  },
  {
    // 404 Route
    path: '*',
    name: 'Error404',
    component: () => import( /* webpackChunkName: "error404" */ '../views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',//nginx config : try_files $uri $uri/ /index.html;
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (from.name) {
    Nprogress.start();
    store.commit('LOADED');
  } else {
    document.onreadystatechange = () => {
      if (document.readyState == 'complete') {
        store.commit('LOADED');
      }
    }
  }
  next()
})

router.afterEach((to, from) => {
  if (from.name) {
    Nprogress.done = true;
  }
  closeMenu();
  closeModal();
})

export default router