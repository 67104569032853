<template>
<!--
    Requires Vuex with a menuItems array in store state
    menuItems: [
    {
        name: String,
        text: String,
        root: String,
        active: Boolean
    },...
    ]
    
 -->
    <div>
        <header class="container-fluid bg-light navbar-container">
            <nav class="navbar navbar-light navbar-expand-xl bg-light navigation-clean-button">
                <div class="container">
                    <router-link class="navbar-brand pr-2 mr-0" to='/'><img class="navbar-brand__logo"
                            src="../assets/img/png/hibou.png" alt="Logo">BARES Orthoptiste</router-link>
                    <button data-toggle="collapse" class="navbar-toggler" data-target="#navcol-1">
                        <span class="sr-only">Toggle navigation</span><span class="navbar-toggler-icon"></span></button>
                    <div class="collapse navbar-collapse" id="navcol-1">
                        <ul class="nav navbar-nav d-flex flex-grow-1">
                            <li v-for="item of menuItems" :key="item.name">
                                <router-link class="nav-link" :class="{'active': item.active}" :to="item.root">
                                    {{item.text}}</router-link>
                            </li>
                            <!-- <li class="nav-item ml-0 ml-xl-auto mt-4 my-xl-auto mr-auto mr-lg-3" role="presentation"><router-link
                                    class="shadow px-3 py-2 nav-link text-white bg-warning border shadow-sm"
                                    to="./covid">CoVID-19</router-link></li> -->
                        </ul>
                        <span class="navbar-text actions"><a
                                class="btn btn-light border border-primary  shadow-sm action-button" role="button"
                                data-toggle="modal" data-target="#bootstrapModal" @click='closeMenu'>Prendre RDV</a></span>

                    </div>
                </div>
            </nav>
            <nprogress-container></nprogress-container>
        </header>
    </div>
</template>

<script>
    import {
        mapState
    } from 'vuex';
  import NprogressContainer from 'vue-nprogress/src/NprogressContainer';
  import {closeMenu} from '../router/index';

    export default {
        name: 'Navbar',
        data() {
            return {

            }
        },
        computed: {
            ...mapState(['menuItems'])
        },
        components: {
            NprogressContainer
        },
        methods:{
            closeMenu
        }
    }
</script>

<style lang="scss" scoped>
    .bg-warning{
        border-radius: 2em;
    }
</style>