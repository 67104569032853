import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vueAnalytics from 'vue-analytics'
import store from './store'
import Nprogress from 'vue-nprogress'

Vue.config.productionTip = false
Vue.use(vueAnalytics, {
  id:'UA-177236828-1',
  router
})

const nprogress = new Nprogress();

new Vue({
  router,
  store,
  nprogress,
  vueAnalytics,
  render: h => h(App)
}).$mount('#app')

