<template>
    <!-- 
    Carousel component
    requires vuex with carousel object:
    carousel: {
        key1: ['filename', 'filename'...],
        key2: ['filename', 'filename'...]
        ...
    }
    passed prop keyCarousel must match a key inside carousel state object
    images are inside assets/img
 -->
    <div class="col">
        <div class="carousel slide" data-ride="carousel" id="carousel-1">
            <div class="carousel-inner rounded shadow-sm">
                <!-- Only the first created is marked "active" -->
                <div v-for="(item, index) of carousel[keyCarousel]" class="carousel-item"
                    :class="{active: (index === 0)}" :key='index'><img class="w-100 d-block"
                        :src="getImgSrc(carousel[keyCarousel][index])" alt="Slide Image">
                </div>
            </div>
            <div><a class="carousel-control-prev" href="#carousel-1" data-slide="prev"><span
                        class="carousel-control-prev-icon"></span><span class="sr-only">Previous</span></a><a
                    class="carousel-control-next" href="#carousel-1" data-slide="next"><span
                        class="carousel-control-next-icon"></span><span class="sr-only">Next</span></a>
            </div>
            <ol class="carousel-indicators">
                <!-- Only the first created is marked "active" -->
                <li v-for="(item, index) of carousel[keyCarousel]" data-target="#carousel-1" :data-slide-to="index"
                    :class="{active : (index === 0)}" :key='index'></li>
            </ol>
        </div>
    </div>
</template>

<script>
    import {
        mapState
    } from 'vuex';

    export default {
        name: 'Carousel',
        props: {
            keyCarousel: {
                type: String,
                required: true
            }
        },
        computed: {
            ...mapState(['carousel'])
        },
        mounted() {},
        methods: {
            getImgSrc(img) {
                return require(`@/assets/img/${img}`);
            }
        }

    }
</script>

<style lang="scss" scoped>

</style>