<template>
  <div id="app">
    <!--     <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <Navbar class="navbarComponent" />
    <router-view id="pageContent" v-if="firstLoaded" />
    <div class="spinner" v-if="!firstLoaded">
      <img class='img-fluid' src="@/assets/img/gif/spinner.gif" alt="Chargement en cours">
    </div>
    <Footer />
    <BootstrapModal>
      <template v-slot:title>Prenez Rendez-Vous :</template>
      <template v-slot:content>
        <p> Le module de prise de rendez-vous en ligne est actuellement en construction.<br>
          Veuillez vous munir de votre ordonnance et utiliser le numéro de téléphone ci-dessous pour
          prendre rendez-vous : <br /></p>
        <p class="text-center"><a class="btn btn-primary text-light" href="tel:0561513480">05.61.51.34.80</a></p>
      </template>
    </BootstrapModal>
  </div>
</template>

<style lang="scss">
  @import "./sass/main.scss";

  .navbarComponent {
    position: sticky;
    top: 0;
    z-index: 2000;
  }

  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #nav {

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }

  .spinner {
    text-align: center;
  }
</style>

<script>
  import Navbar from './components/Navbar.vue';
  import Footer from './components/Footer.vue';
  import BootstrapModal from './components/BootstrapModal.vue';
  import {
    mapState
  } from 'vuex';

  export default {
    name: 'app',
    components: {
      Navbar,
      Footer,
      BootstrapModal
    },
    computed: {
      ...mapState(['firstLoaded'])
    }
  }
</script>