import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    firstLoaded: false,
    menuItems: [{
        name: 'Home',
        text: 'Accueil',
        root: '/',
        active: false
      },
      {
        name: 'Cabinet',
        text: 'Le Cabinet',
        root: '/cabinet',
        active: false
      },
      {
        name: 'Consultations',
        text: 'Les consultations',
        root: '/consultations',
        active: false
      },
      {
        name: 'Infos',
        text: 'Infos pratiques',
        root: '/infos',
        active: false
      },
    ],
    carousel:{
      cabinet:[
        'cabinet-carrousel-1.jpg',
        'cabinet-carrousel-2.jpg',
        'cabinet-carrousel-3.jpg',
        'cabinet-carrousel-4.jpg',
        'cabinet-carrousel-5.jpg'
      ],
      accueil:[
        'accueil-carrousel-1.jpg',
        'accueil-carrousel-2.jpg',
        'accueil-carrousel-3.jpg',
      ],
      covid:[
        'covid-carrousel-1.jpg',
        'covid-carrousel-2.jpg',
        'covid-carrousel-3.jpg',
      ]
    }
  },
  mutations: {
    SET_ACTIVE(state, index) {
      state.menuItems[index].active = true;
    },
    DEACTIVATE(state, index) {
      state.menuItems[index].active = false;
    },
    LOADED(state){
      state.firstLoaded = true;
    }
  },
  actions: {
    // tests if an item into menuItems is the current route, if so, it's pinned "active" and navbar add active class to it, payload is the route this.$route
    updateActive(context, payload) {
      for (let i = 0; i < context.state.menuItems.length; i++) {
        if (context.state.menuItems[i].name === payload.name) {
          context.commit('SET_ACTIVE', i);
        } else {
          context.commit('DEACTIVATE', i);
        }
      }
      document.activeElement.blur();
    }

  },
  modules: {}
})