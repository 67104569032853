<template>
    <!-- Footer -->
    <div class="footer-basic py-0 mb-1">
        <footer class="bg-light">
            <ul class="list-inline pt-4">
                <li class="list-inline-item"><router-link to="/mentionslegales">Mentions légales</router-link></li>
                <li class="list-inline-item"><router-link to="/contact">Contact</router-link></li>
            </ul>
            <p class="copyright">Camille Barès - Julien Ferand © 2024</p>
        </footer>
    </div>
</template>

<script>
    export default {
        name: 'Footer'
    }
</script>

<style lang="scss" scoped>

</style>